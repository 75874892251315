import "c3/c3.min.css";
import * as moment from 'moment';
import * as React from "react";
import ReactECharts from 'echarts-for-react';
import {useIntl} from "react-intl";
import {IDataRecord} from "constants/DeviceConstants";
import "../../less/DeviceData.css";
import "../../less/Chart.css";
import DeviceNoData from "./DeviceNoData";
import {DateTimeFormat} from "../../constants/Constants";
import {useEffect, useState} from "react";

type TimeSeriesData = [Date, number];

interface IChart {
    data: TimeSeriesData[];
    parameterName: string;
    parameterUnit: string;
}

interface IDeviceChartProps {
    records: IDataRecord[];
    thresholdMax?: number;
    thresholdMin?: number;
    parameterId: number;
    parameterMaxValue: number;
    parameterMinValue: number;
    customName: string;
    unit: string;
}

const DeviceEChartsChart = (props: IDeviceChartProps) => {
    const intl = useIntl();
    const [height, setHeight] = useState("400px");
    useEffect(() => {
        const updateHeight = () => setHeight(window.innerWidth < 768 ? "300px" : "480px");
        window.addEventListener("resize", updateHeight);
        updateHeight(); // Set initial height
        return () => window.removeEventListener("resize", updateHeight);
    }, []);



    const getParameterName = (): string => {
        return (props.customName
            ? props.customName
            : intl.formatMessage({id: `device.chart.parameter_${props.parameterId}`}));
    }

    const getParameterUnit = (): string => {
        return ` [${props.unit ? props.unit : intl.formatMessage({id: `device.chart.parameter.unit_${props.parameterId}`})
        }]`
    }

    const getChartSettings = (): IChart => {
        return {
            data: props.records.map(r => [moment.utc(r.date).local().toDate(), r.value]),
            parameterName: getParameterName(),
            parameterUnit: getParameterUnit()
        }
    }

    const settings = getChartSettings();

    const markArea: { data: any[][] } = {data: []};
    const markLine: { symbol: string[], animation: boolean, silent: boolean, label: { show: boolean }, data: any[] } = {
        symbol: ['none'],
        animation: false,
        silent: true,
        label: {show: false},
        data: []
    };

    if (props.thresholdMax !== null && props.thresholdMax !== props.parameterMaxValue) {
        markArea.data.push([{yAxis: props.thresholdMax}, {
            yAxis: Infinity,
            itemStyle: {color: '#E69598', opacity: 0.3}
        }]);
        markLine.data.push({
            xAxis: 0,
            yAxis: props.thresholdMax,
            lineStyle: {color: '#E69598', width: 2, type: 'dashed'}
        });
    }

    if (props.thresholdMin !== null && props.thresholdMin !== props.parameterMinValue) {
        markArea.data.push([{yAxis: -Infinity}, {
            yAxis: props.thresholdMin,
            itemStyle: {color: '#6194BC', opacity: 0.3}
        }],);
        markLine.data.push({
            xAxis: 0,
            yAxis: props.thresholdMin,
            lineStyle: {color: '#6194BC', width: 2, type: 'dashed'}
        });
    }

    const options = {
        grid: {top: 8, right: 8, bottom: 50, left: 40},     
        xAxis: {
            type: 'time',
            axisLabel: {
                show: true,
                rotate: 45,
                fontSize: 10,
                position: 'right',
            },
            boundaryGap: ['1%', '1%'],
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: (value: number) => value.toFixed(1)
            }
        },

        series: [
            {
                data: settings.data,
                type: 'line',
                markArea: markArea,
                markLine: markLine,
                smooth: true,
                symbol: 'none',
            },
        ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {type: 'line'},
            formatter: function (params: any[]) {
                const param = params[0];
                const date = param.value[0];
                const value = param.value[1];
                
                let tooltipText = '<div class="tooltip-content">';
                tooltipText += '<div class="date">' + moment.utc(date).local().format(DateTimeFormat) + '</div>';
                tooltipText += '<div>';

                tooltipText += '<span class="marker" style="color:' + param.color + '">●</span>';
                tooltipText += '<span class="series-name">' + settings.parameterName + '</span>';
                tooltipText += '<span class="value">' +  value.toFixed(1) + ' ' + settings.parameterUnit + '</span><br/>';

                tooltipText += '</div>';
                tooltipText += '</div>';

                return tooltipText;
            }
        },

        dataZoom: [
            {
                type: 'slider',  // Zoom using a slider
                show: true,
                xAxisIndex: [0],  // Enable zoom on the x-axis
                start: 0,         // Initial zoom start position (0% of the range)
                end: 100,          // Initial zoom end position (100% of the range)
                filterMode: 'none',
            },
            {
                type: 'inside',   // Zoom with mouse wheel or pinch zoom (without a slider)
                xAxisIndex: [0],
                start: 0,
                end: 100,
                filterMode: 'none',
            }
        ]
    };

    return (
        <div className="chart">
            <div className="title">{settings.parameterName+ " " + settings.parameterUnit}</div>
            {settings.data && settings.data.length > 0 ?
                <div className="chart-content">
                    <ReactECharts
                        option={options}
                        notMerge={true}
                        lazyUpdate={true}
                        theme={"theme_name"}
                        style={{ width: "100%", height }}
                    />
                </div> : <DeviceNoData/>}
        </div>
    );
}

export default DeviceEChartsChart;